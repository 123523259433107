import BaseRenderer from "diagram-js/lib/draw/BaseRenderer";

import {
  append as svgAppend,
  attr as svgAttr,
  classes as svgClasses,
  create as svgCreate,
  remove as svgRemove,
} from "tiny-svg";

import { getRoundRectPath } from "bpmn-js/lib/draw/BpmnRenderUtil";
import { is } from "bpmn-js/lib/util/ModelUtil";
import { isAny } from "bpmn-js/lib/features/modeling/util/ModelingUtil";
import {
  isEntryinData,
  getAttributesByKey,
  quickCheckAccess,
} from "../utils/accessMapFunctions";

var CryptoJS = require("crypto-js");

const HIGH_PRIORITY = 3000,
  TASK_BORDER_RADIUS = 2;

export default class CustomRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer) {
    super(eventBus, HIGH_PRIORITY);

    this.bpmnRenderer = bpmnRenderer;

    var e2eTreeCipher = localStorage.getItem("e2eTree");
    var libraryTreeCipher = localStorage.getItem("libraryTree");
    var subscriptionCipher = localStorage.getItem("subscription");

    var blueprintsTreeCipher = localStorage.getItem("blueprintsTree");
    var workPackagesTreeCipher = localStorage.getItem("workPackagesTree");

    this.e2eTree = JSON.parse(
      CryptoJS.AES.decrypt(e2eTreeCipher, "my-secret-key@123").toString(
        CryptoJS.enc.Utf8
      )
    );
    this.libraryTree = JSON.parse(
      CryptoJS.AES.decrypt(libraryTreeCipher, "my-secret-key@123").toString(
        CryptoJS.enc.Utf8
      )
    );
    this.subscription = CryptoJS.AES.decrypt(
      subscriptionCipher,
      "my-secret-key@123"
    ).toString(CryptoJS.enc.Utf8);

    this.blueprintsTree = JSON.parse(
      CryptoJS.AES.decrypt(blueprintsTreeCipher, "my-secret-key@123").toString(
        CryptoJS.enc.Utf8
      )
    );
    this.workPackagesTree = JSON.parse(
      CryptoJS.AES.decrypt(
        workPackagesTreeCipher,
        "my-secret-key@123"
      ).toString(CryptoJS.enc.Utf8)
    );

    this.trees = [
      this.e2eTree,
      this.libraryTree,
      this.blueprintsTree,
      this.workPackagesTree,
    ];
  }

  canRender(element) {
    // only render tasks and events (ignore labels)
    return (
      isAny(element, ["bpmn:SubProcess", "bpmn:Task"]) && !element.labelTarget
    );
  }

  drawShape(parentNode, element) {
    const shape = this.bpmnRenderer.drawShape(parentNode, element);
    let modelGUID = window.location.pathname.split("/")[3];
    let objName = element.businessObject.name;

    /* 1- functional process library entry model skip customization */

    let objAttributes = [];

    getAttributesByKey(objName, this.trees, "name", objAttributes);
    if (is(element, "bpmn:SubProcess")) {
      /**first level check: execptions:*/

      /**Skip lane customization at library entry map level */
      if (modelGUID === this.libraryTree?.GUID) {
        return shape;
      }

      const tiltedRect = drawTiltedRect(parentNode, 100, 80);
      prependTo(tiltedRect, parentNode);
      svgRemove(shape);

      svgAttr(tiltedRect, {
        transform: "translate(0, -15)",
        fill: "#D2D2D2",
        stroke: "#000",
        strokeWidth: 2,
      });
      //check access


      /**first level check: exceptions */
      if (!isEntryinData(objName, this.trees)) return shape;

      if (objAttributes.length > 0) {

        /**second level check: subscription */
        if (!quickCheckAccess(objAttributes[0]?.inTrial, this.subscription))
          return shape;
        /**third level check: assignment, added exception for the entry map and business processes */
        if (
          !objAttributes[0]?.hasAssignment === true &&
          !(modelGUID === this.libraryTree?.GUID)
        ) {
          return shape;
        }
      }

      //draw green plus icon
      const rect = drawRect(parentNode, 20, 20, TASK_BORDER_RADIUS, "#52B415");
      svgAttr(rect, {
        transform: "translate(-15, -10)",
      });
      var text = svgCreate("text");

      svgAttr(text, {
        fill: "#52B415",
        transform: "translate(-10, 5)",
      });

      svgClasses(text).add("djs-label");

      svgAppend(text, document.createTextNode("+"));

      svgAppend(parentNode, text);
      
      return shape;
    }

    if (is(element, "bpmn:Task")) {
      /**first level check: exceptions */
      if (!isEntryinData(objName, this.trees)) return shape;

      if (objAttributes.length > 0) {
        /**second level check: subscription */
        if (!quickCheckAccess(objAttributes[0]?.inTrial, this.subscription))
          return shape;
        /**third level check: assignment, added exception for the entry map and business processes */
        if (
          !objAttributes[0]?.hasAssignment === true &&
          !(modelGUID === this.libraryTree?.GUID)
        ) {
          return shape;
        }
      }

      // #b41515 hex inaccessible
      const rect = drawRect(parentNode, 20, 20, TASK_BORDER_RADIUS, "#52B415");
      svgAttr(rect, {
        transform: "translate(-15, -10)",
      });
      var text = svgCreate("text");

      svgAttr(text, {
        fill: "#52B415",
        transform: "translate(-10, 5)",
      });

      svgClasses(text).add("djs-label");

      svgAppend(text, document.createTextNode("+"));

      svgAppend(parentNode, text);

      return shape;
    }

    return shape;
  }

  getShapePath(shape) {
    if (is(shape, "bpmn:SubProcess")) {
      return getRoundRectPath(shape, TASK_BORDER_RADIUS);
    }
    if (is(shape, "bpmn:Task")) {
      return getRoundRectPath(shape, TASK_BORDER_RADIUS);
    }

    return this.bpmnRenderer.getShapePath(shape);
  }
}

CustomRenderer.$inject = ["eventBus", "bpmnRenderer"];

// helpers //////////

// draw diamond
function drawTiltedRect(parentGfx, width, height) {
  var points = [
    { x: 0, y: 0 },
    { x: width + 30, y: 0 },
    { x: width + 35, y: height },
    { x: 5, y: height },
  ];

  var pointsString = points
    .map(function (point) {
      return point.x + "," + point.y;
    })
    .join(" ");

  var polygon = svgCreate("polygon");
  svgAttr(polygon, {
    points: pointsString,
  });
  //svgAttr(polygon, attrs);

  svgAppend(parentGfx, polygon);

  return polygon;
}

// draw diamond
function drawDiamond(parentGfx, width, height) {
  var x_2 = width / 2;
  var y_2 = height / 2;

  var points = [
    { x: x_2, y: 0 },
    { x: width, y: y_2 },
    { x: x_2, y: height },
    { x: 0, y: y_2 },
  ];

  var pointsString = points
    .map(function (point) {
      return point.x + "," + point.y;
    })
    .join(" ");

  var polygon = svgCreate("polygon");
  svgAttr(polygon, {
    points: pointsString,
  });

  svgAppend(parentGfx, polygon);

  return polygon;
}

// copied from https://github.com/bpmn-io/bpmn-js/blob/master/lib/draw/BpmnRenderer.js
function drawRect(parentNode, width, height, borderRadius, strokeColor) {
  const rect = svgCreate("rect");

  svgAttr(rect, {
    width: width,
    height: height,
    rx: borderRadius,
    ry: borderRadius,
    stroke: strokeColor || "#000",
    strokeWidth: 2,
    fill: "#fff",
  });

  svgAppend(parentNode, rect);

  return rect;
}

// copied from https://github.com/bpmn-io/diagram-js/blob/master/lib/core/GraphicsFactory.js
function prependTo(newNode, parentNode, siblingNode) {
  parentNode.insertBefore(newNode, siblingNode || parentNode.firstChild);
}
